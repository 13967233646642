// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YYQ1DZXTkRDwOvkD03bR{margin:0 auto;width:1400px}.g6iuKFjLXxdJoCvnGaGr{width:100%;height:1px;background:var(--color-neutrals-silver)}.M27QQWT0EVFLsRWaZQHQ{background:var(--color-neutrals-quartz);margin:40px 0 0;padding:0 56px}@media(max-width: 1024px){.M27QQWT0EVFLsRWaZQHQ{padding:0 24px}}.yYYKnHWvlzddN6ZzQuEp{display:flex}.yYYKnHWvlzddN6ZzQuEp svg{margin-top:10px}.rnuS07rZRXS0a3zJlBKI{width:600px;margin-left:16px}.bn3QJ1Z8faMPOSDC6TJ_{position:relative;bottom:21px;left:402px}`, "",{"version":3,"sources":["webpack://./src/ui/legal-banner/legal.banner.styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,aAAA,CACA,YAAA,CAEJ,sBACE,UAAA,CACA,UAAA,CACA,uCAAA,CAEF,sBACE,uCAAA,CACA,eAAA,CACA,cAAA,CACA,0BAJF,sBAKI,cAAA,CAAA,CAGJ,sBACE,YAAA,CACA,0BACC,eAAA,CAIH,sBACE,WAAA,CACA,gBAAA,CAEF,sBACE,iBAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".container {\n    margin: 0 auto;\n    width: 1400px;\n}\n.divider {\n  width: 100%;\n  height: 1px;\n  background: var(--color-neutrals-silver);\n}\n.legalBannerWrapper {\n  background: var(--color-neutrals-quartz);\n  margin: 40px 0 0;\n  padding: 0 56px;\n  @media (max-width: 1024px) {\n    padding: 0 24px;\n  }\n}\n.legalBanner {\n  display: flex;\n  & svg {\n   margin-top: 10px;\n  }\n\n}\n.legalText {\n  width: 600px;\n  margin-left: 16px;\n}\n.learnMoreLink {\n  position: relative;\n  bottom: 21px;\n  left: 402px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `YYQ1DZXTkRDwOvkD03bR`,
	"divider": `g6iuKFjLXxdJoCvnGaGr`,
	"legalBannerWrapper": `M27QQWT0EVFLsRWaZQHQ`,
	"legalBanner": `yYYKnHWvlzddN6ZzQuEp`,
	"legalText": `rnuS07rZRXS0a3zJlBKI`,
	"learnMoreLink": `bn3QJ1Z8faMPOSDC6TJ_`
};
export default ___CSS_LOADER_EXPORT___;
