import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import productUnderConsiderationStyles from "./product-under-consideration.styles";
import {
  Paragraph,
  ProductLogo,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { AppContext } from "../../app.context";
import { useTranslation } from "react-i18next";
import PreviewProductLogo from "../../assets/images/preview-product-logo.svg";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";
import classNames from "classnames";
const useStyles = makeStyles(productUnderConsiderationStyles);

const ProductUnderConsideration = () => {
  const isGCOM3606 = useFeatureFlag(GCOM_3606__fontUpdate);
  const classes = useStyles();
  const { productName, imageLoc, isPreviewMode, surveyInstructions } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className={classNames(isGCOM3606 ? classes.rootGCOM3606 : classes.root)}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"p4"} boldness={"regular"} color={"iron"}>
            {t("Product under consideration")}
          </TypographyComponent>
          <div className={classes.logoPtagWrapper}>
            <ProductLogo
              logo={isPreviewMode ? PreviewProductLogo : imageLoc}
              imageWidth={"32px"}
              imageHeight={"32px"}
            />

            <TypographyComponent rootClassName={"gx-product-name"} color={"carbon"} styling={"p2"} boldness={"semi"}>
              {productName}
            </TypographyComponent>
          </div>
          <TypographyComponent color={"iron"} styling={"p4"} boldness={"regular"}>
            {t("Questionnaire Instructions")}
          </TypographyComponent>
          <TypographyComponent
            color={"coal"}
            boldness={"regular"}
            rootClassName={"gx-survey-instructions"}
            styling={"p3"}
          >
            {surveyInstructions}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Paragraph type={"p4"}>{t("Product under consideration")}</Paragraph>
          <div className={classes.logoPtagWrapper}>
            <ProductLogo
              logo={isPreviewMode ? PreviewProductLogo : imageLoc}
              imageWidth={"32px"}
              imageHeight={"32px"}
            />
            <Paragraph rootClassName={"gx-product-name"} type={"p2"} boldness={"semi"}>
              {productName}
            </Paragraph>
          </div>
          <Paragraph type={"p4"}>{t("Questionnaire Instructions")}</Paragraph>
          <Paragraph rootClassName={"gx-survey-instructions"} type={"p3"}>
            {surveyInstructions}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};

export default ProductUnderConsideration;
