import { useTranslation } from "react-i18next";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import styles from "./sharing-responses.styles.module.scss";
import React from "react";
import SharingResponsesImg from "../../../../assets/images/quick-start/sharing-responses.svg";

const SharingResponsesComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TypographyComponent type={"h5"} boldness={"semi"}>
        {t("Sharing responses")}
      </TypographyComponent>
      <div className={styles.sharingResponsesImageContainer}>
        <img src={SharingResponsesImg} alt={t("Sharing responses")} />
      </div>
      <div className={styles.description}>
        <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
          {t(
            "Your responses to the questionnaire will only be available to the evaluation team. Once you elect to share your responses, any updates that are made will be communicated in real-time. Responses cannot be unshared."
          )}
        </TypographyComponent>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about responding to questionnaires")}
            target={"_blank"}
            href={
              "https://intercom.help/gartner-buysmart-help-center/en/articles/8283411-responding-to-a-buysmart-questionnaire"
            }
          />
        </div>
      </div>
    </div>
  );
};
export default SharingResponsesComponent;
