import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import productUnderConsiderationStyles from "./product-under-consideration.styles";
import { Paragraph, ProductLogo } from "gx-npm-ui";
import { AppContext } from "../../app.context";
import { useTranslation } from "react-i18next";
import PreviewProductLogo from "../../assets/images/preview-product-logo.svg";

const useStyles = makeStyles(productUnderConsiderationStyles);

const ProductUnderConsideration = () => {
  const classes = useStyles();
  const { productName, imageLoc, isPreviewMode, surveyInstructions } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Paragraph type={"p4"}>{t("Product under consideration")}</Paragraph>
      <div className={classes.logoPtagWrapper}>
        <ProductLogo logo={isPreviewMode ? PreviewProductLogo : imageLoc} imageWidth={"32px"} imageHeight={"32px"} />
        <Paragraph rootClassName={"gx-product-name"} type={"p2"} boldness={"semi"}>
          {productName}
        </Paragraph>
      </div>
      <Paragraph type={"p4"}>{t("Questionnaire Instructions")}</Paragraph>
      <Paragraph rootClassName={"gx-survey-instructions"} type={"p3"}>
        {surveyInstructions}
      </Paragraph>
    </div>
  );
};

export default ProductUnderConsideration;
