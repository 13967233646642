import { colorPalette } from "gx-npm-common-styles";

const productUnderConsidersationStyles = {
  root: {
    "& p": {
      color: colorPalette.neutrals.iron.hex,
      "&.gx-product-name": { color: colorPalette.neutrals.carbon.hex, marginLeft: 16 },
      "&.gx-survey-instructions": {
        color: colorPalette.neutrals.coal.hex,
        marginTop: 8,
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
      },
    },
    backgroundColor: colorPalette.basic.white.hex,
    padding: 32,
    borderRadius: "8px",
    minHeight: 260,
  },
  logoPtagWrapper: { display: "flex", alignItems: "center", marginTop: 12, marginBottom: 40 },
};

export default productUnderConsidersationStyles;
